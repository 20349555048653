
try{
    let auditFormSelect = document.querySelector('select[name=p44]');
    let cartBtns = document.querySelectorAll('.cart-btn');


    cartBtns.forEach((el) => {
        el.addEventListener('click', () => {
            let checkText = el.parentNode.parentNode.querySelector('.offer-post__title').innerText;
            for (let i = 0; i < auditFormSelect.options.length; i++) {
                if (auditFormSelect.options[i].innerText == checkText){
                    auditFormSelect.options[i].selected = true;
                }
            }
        });
    });
}catch(e){

}

try{
    document.querySelector('.header-nav__btn').addEventListener('click',()=>{
        document.querySelector('.header-nav').classList.toggle('show');
    });
}catch (e) {
    
}
