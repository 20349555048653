import "./uikit";
import "./map";
import "./section-nav";
import "./homepage-scroll";
import "./togglers";
import "./homepage";
import "./blog";
import "./services";
import "./project";
import "./project-item";
import "./type-item";
import "./service-item";
import "./page-404";
import "./preloader";
import "./validation";
import "./script";
import "./landos";
