import ScrollMagic from "scrollmagic/scrollmagic/uncompressed/ScrollMagic";
(function () {

  if (document.querySelector('.js-landos')) {

    //Выбор проблем 
    let problemsList = document.querySelector(".landos-mark__list");

    problemsList.addEventListener('change', function (evt) {
      let hiddenAdvices = document.querySelectorAll(".advice-result__item");
      hiddenAdvices.forEach(function (item) {
        item.style.display = "none";
      });
      let adviceId = evt.target.dataset.show;
      let advice = document.getElementById(adviceId);
      advice.style.display = "block";
    });


    //Анимация скроллинга

    let controller = new ScrollMagic.Controller();

    let scene1 = new ScrollMagic.Scene({
        triggerElement: '.landos-banner'
      })
      .setClassToggle('.landos-container', 'landos-container-animation')
      .addTo(controller);
      
      let scene2 = new ScrollMagic.Scene({
        triggerElement: '.advice'
      })
      .setClassToggle('.advice', 'advice-animation')
      .addTo(controller)

      let scene3 = new ScrollMagic.Scene({
        triggerElement: '.tariffs'
      })
      .setClassToggle('.tariffs', 'tariffs-animation')
      .addTo(controller)

      let scene4 = new ScrollMagic.Scene({
        triggerElement: '.targets'
      })
      .setClassToggle('.targets', 'targets-animation')
      .addTo(controller)

      let scene5 = new ScrollMagic.Scene({
        triggerElement: '.cases'
      })
      .setClassToggle('.cases', 'cases-animation')
      .addTo(controller)

      let scene6 = new ScrollMagic.Scene({
        triggerElement: '.callback'
      })
      .setClassToggle('.callback', 'callback-animation')
      .addTo(controller)

      try{
        document.querySelector('.header__btn').addEventListener('click',()=>{
          document.querySelector('.header-nav').classList.toggle('show');
          document.querySelector('.header__btn').classList.toggle('opened');
          document.querySelector('.header-contact').classList.toggle('hidden');
        });
      }catch (e) {
        
    }

  }

})()